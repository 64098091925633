const urls = {
    local: 'http://ndsu-variety-trials-backend.test/',
    dev: 'https://ndsu-trials-dev.frb.io/',
    prod: 'https://vt-data.ag.ndsu.edu/'
}

let url = urls.prod;

if(process.env.NODE_ENV === 'development') {
    url = urls.local;
}

const REACT_APP_STAGE = process.env.REACT_APP_STAGE;

if (REACT_APP_STAGE && REACT_APP_STAGE === 'development') {
    url = urls.dev;
}

console.log('url', url);
export const API_URL = urls.prod;
