
import React, {useEffect, useState} from 'react';
import './styles/App.scss';
import classNames from 'classnames';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";

import { isIE } from "react-device-detect";

import logo from './images/logo.svg';

import Compare from './components/Compare'
import Footer from './components/Footer'
import TrialDetail from "./components/TrialDetail";
import TrialList from "./components/TrialList";
import Header from "./components/Header";

export const UserContext = React.createContext();
export const AppContext = React.createContext();

function App() {

  console.log('process.env', process.env);
  const [showNav, setShowNav] = useState();
    const [prefs, setPrefs] = useState({});
    const [appSettings, setAppSettings] = useState({foo: 'bar'});

    let classes = classNames( 'collapse', 'navbar-collapse', {
    'show': showNav,
  })

    const updatePrefs = (p) => {
        console.log('updatePrefs');
        console.log('p', p);
        window.localStorage.setItem('prefs', JSON.stringify(p));
        setPrefs(p);
    }


    useEffect(() => {

        console.log('⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐')
        const storedPrefs = window.localStorage.getItem('prefs');
        if (!storedPrefs) {
            console.log('no prefs in local storage');
            const defaultPrefs = {
                filters: {},
                hiddenListColumns: ['Planting Date', 'Harvest Date', 'Previous Crop'],
            }
            updatePrefs(defaultPrefs);
        } else {
            console.log('prefs already stored in local storage')
            setPrefs(JSON.parse(window.localStorage.getItem('prefs')))
        }

    }, []);

  return (
     <UserContext.Provider value={{prefs, updatePrefs}}>
         <div className="site">
             <Router>
                 <Header />
                 <div className="site-main container-fluid mt-2 mb-5">

                     {isIE ? (
                         <div className='text-center mt-4'>
                             <h1>Hi there. You’re using an unsupported browser.</h1>
                             <p>Please use a modern browser like Chrome, Firefox, Safari, Opera or Edge.</p>
                         </div>
                     ) : (
                         <Switch>

                             <Route path="/" exact={true}>
                                 <TrialList />
                             </Route>

                             <Route path="/list" exact={true}>
                                 <TrialList />
                             </Route>

                             <Route path="/list/:crop">
                                 <TrialList />
                             </Route>

                             <Route path="/compare">
                                 <Compare />
                             </Route>

                             <Route path="/view/:trialId">
                                 <TrialDetail />
                             </Route>

                         </Switch>
                     )}
                 </div>
             </Router>

             <Footer />
         </div>
     </UserContext.Provider>
  );
}

export default App;
