import React, {useState, useEffect} from 'react';



const SiteDescription = ({trial}) => {

    let items = [];
    if (trial.organic) {
        items.push('Organic')
    }
    if (trial.irrigated) {
        items.push('Irrigated')
    }
    if (trial.noTill) {
        items.push("No-Till")
    }
    if (items.length === 0) {
        items.push('Conventional')
    }
    return (
        <span>{items.join(', ')}</span>

    );
};

SiteDescription.propTypes = {};

export default SiteDescription;
