import React, {useState, useEffect} from 'react';

import {Bar} from 'react-chartjs-2';
import clone from "clone";

const BarChart = ({trial, attributes, isComparing}) => {
    const [chartable, setChartable] = useState(attributes.filter(column => column.type === 'number'));
    const [allChartable, setAllChartable] = useState(false);
    const [allVarieties, setAllVarieties] = useState(false);
    const [trialData, setTrialData] = useState([]);
    const [datasets, setDatasets] = useState([]);

    console.log('trial', trial);
    const chartKey = trial.columns[0].handle;
    console.log('chartKey', chartKey);

    console.log('chartable', chartable);

    useEffect(() => {
        console.log('EFFECT')
        // Set "yeild" as checked if it exists
        let newChartable = chartable.map(item => item.handle.toLowerCase() === 'yield' ? {
            ...item,
            show: true
        } : item);
        setChartable(newChartable);
        trial.data.map(d => d.show = true);
        setTrialData([...trial.data, {variety: 'Mean', ...trial.trialDataComputedMean}])
    }, []);

    useEffect(() => {
        console.log('datasets changbed')
        let newDatasets = [];
        console.log('chartable', chartable);
        chartable.forEach((column, index) => {
            console.log('column', column);
            console.log('index', index);

            if (column.show) {
                newDatasets.push({
                    label: `${column.heading} LSD 5% = ${trial.trialDataComputedLsd005[column.handle]}`,
                    data: trialData.filter(datum => datum.show).map(datum => datum[column.handle]),
                    backgroundColor: colors[index],
                    borderWidth: 0,
                })
            }
        });
        setDatasets(newDatasets);
    }, [chartable, trialData]);

    const colors = [
        '#0a5640',
        '#77bea9',
        '#386cb0',
        '#ffcc00',
        '#f0027f',
        '#bf5b17',
        '#beaed4',
        '#fdc086',
        '#ffe680',
        '#a6cee3',
        '#8da0cb',
        '#27a480',
        '#fb9a99',
        '#e31a1c',
        '#fdbf6f',
        '#ff7f00',
    ];

    const handleVarietyCheck = (event) => {
        const {value} = event.target;

        console.log('value', value);
        const cloned = clone(trialData);
        console.log('cloned', cloned);

        if (value === 'all') {
            setAllVarieties(!allVarieties);
            cloned.forEach(d => {
                d.show = !allVarieties;
            })
        } else {

            cloned.forEach(d => {
                if (d.variety === value) {
                    d.show = !d.show;
                }
            })
        }
        console.log('cloned', cloned);
        setTrialData(cloned);
    }

    const handleCheck = (event) => {
        console.log('event', event);
        console.log('event.target', event.target);
        console.log('event.target.value', event.target.value);
        console.log('chartable', chartable);
        let newChartable = [...chartable];
        if (event.target.value === 'all') {
            setAllChartable(!allChartable);
            newChartable.map((item) => {
                item.show = !allChartable;
                return item;
            })
        } else {

            newChartable.map((item) => {
                if (item.handle === event.target.value) {
                    item.show = !item.show;
                }
                return item;
            })
        }
        console.log('newChartable', newChartable);
        setChartable(newChartable);
    }

    const data = {
        labels: trialData.filter(datum => datum.show).map(datum => datum[chartKey]),
        datasets
    };

    const options = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            // legend: {
            //     position: 'right',
            // },
            // title: {
            //     display: true,
            //     text: 'Chart.js Horizontal Bar Chart',
            // },
        },
    };

    const chartHeight = () => {
        const calculatedHeight = trialData.filter(d => d.show).length * datasets.length * 30;
        const minHeight = trialData.length * 8;
        return calculatedHeight > minHeight ? calculatedHeight : minHeight;
    }

    const Filters = () => (
        <div>

            <div>
                <label className='mb-2'><strong>Attributes to Chart</strong></label>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value="all"
                        checked={allChartable}
                        onChange={handleCheck}
                        id={`chartOption_all`}/>
                    <label className="form-check-label" htmlFor={`chartOption_all`}>
                        All
                    </label>
                </div>
                {chartable.map((item, index) => (
                    <div className="form-check" key={`chartOption${index}`}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value={item.handle}
                            checked={chartable.find(subItem => subItem.handle === item.handle).show === true}
                            onChange={handleCheck}
                            disabled={allChartable}
                            id={`chartOption_${item.handle}`}/>
                        <label className="form-check-label" htmlFor={`chartOption_${item.handle}`}>
                            {item.heading}
                        </label>
                    </div>
                ))}
            </div>
            <div className="mt-4">
                <label className='mb-2'><strong>Varieties to Chart</strong></label>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value="all"
                        checked={allVarieties}
                        onChange={handleVarietyCheck}
                        id={`chartOption_all`}/>
                    <label className="form-check-label" htmlFor={`chartOption_all`}>
                        All
                    </label>
                </div>
                {trialData.map((item, index) => (
                    <div className="form-check" key={`chartOption${index}`}>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            value={item.variety}
                            checked={item.show}
                            onChange={handleVarietyCheck}
                            disabled={allVarieties}
                            id={`chartOption_${index}`}/>
                        <label className="form-check-label" htmlFor={`chartOption_${item.handle}`}>
                            {item.variety}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )


    const HorizontalBarChart = () => (
        <>
            <div className="row">

                {!isComparing && (
                    <div className="col-md-2">
                        <Filters/>
                    </div>
                )}

                <div className="col">
                    {datasets.length > 0 ? (
                        <div>
                            <Bar data={data} options={options} height={chartHeight()}/>
                        </div>
                    ) : (
                        <div className="alert alert-info">
                            Please select at least one attribute to chart.
                        </div>
                    )}
                </div>
            </div>
        </>
    );

    return (
        <div className="mt-4">

            <HorizontalBarChart/>

        </div>
    );
};

BarChart.propTypes = {};

export default BarChart;
