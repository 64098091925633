import React, {useState, useEffect, useRef, useContext} from 'react';

import classNames from 'classnames';
import clone from 'clone';
import parse from 'html-react-parser';
import {
    Link
} from "react-router-dom";
import {Helmet} from "react-helmet";

import Modal from "react-modal";


import {useRouteMatch} from "react-router";

import { CloudDownload, CaretDownFill, CaretUpFill, GearFill } from 'react-bootstrap-icons';

import BarChart from './BarChart'
import SiteDescription from "./SiteDescription";
import Loader from "./Loader"

import {API_URL} from "../utils/paths";
import {UserContext} from "../App";

const TrialDetail = ({id}) => {
    
    const {prefs, updatePrefs} = useContext(UserContext);
    console.log('prefs', prefs);

    const [isLoading, setIsLoading] = useState(false);
    const [trial, setTrial] = useState({});
    const [columnsWithData, setColumnsWithData] = useState([]);
    const [resultColumns, setResultColumns] = useState([]);
    const [viewMode, setViewMode] = useState('table');
    const [decimals, setDecimals] = useState(1);
    const [showTrialInfo, setShowTrialInfo] = useState(id === undefined);
    const [isComparing, setIsComparing] = useState(id !== undefined);
    const match = useRouteMatch();

    const [settingsModalOpen, setSettingsModalOpen] = useState(false);


    const [thTranslate, setThTranslate] = useState(0);
    const [columnTranslate, setColumnTranslate] = useState(0);
    const tableWrapperRef = useRef();
    const tableRef = useRef();
    const tableHeaderRef = useRef();


    useEffect(() => {

        window.scrollTo(0, 0);

        const url = new URL(API_URL + 'api/trials/' + (id ? id : match.params.trialId));
        // const url = new URL('http://ndsu-variety-trials-backend.loc/api/trials/' + trialId);

        setIsLoading(true);
        setDecimals(2);
        setIsComparing(id !== undefined);
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log('result', result);
                    setTrial(result);
                    setIsLoading(false);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }, []);


    const listenToScroll = () => {

        const offsetTop = tableWrapperRef.current?.offsetTop;
        const clientHeight = tableWrapperRef.current?.clientHeight;
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
        const diff = scrollTop - offsetTop;
        if (diff > 0) {
            if (diff < clientHeight - tableHeaderRef.current?.clientHeight) {
                setThTranslate(scrollTop - offsetTop);
            }
        } else {
            setThTranslate(0);
        }

    }


    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        }
    }, []);



    const showColumnInCompare = (column) => {
        let show = false;
        if (column.handle.toLowerCase().includes('yield')) {
            show = true;
        }
        if (column.handle.toLowerCase().includes('variety')) {
            show = true;
        }
        if (column.handle.toLowerCase().includes('brand')) {
            show = true;
        }
        return show;
    }

    useEffect(() => {
        let withData = [];
        if (isComparing) {
            trial.columns?.forEach((column) => {
                let columnHasData = false;
                trial.data.forEach(datum => {
                    if (showColumnInCompare(column) && datum[column.handle]) {
                        columnHasData = true;
                    }
                });
                if (columnHasData) {
                    withData.push(column);
                }
            });
        } else {
            trial.columns?.forEach((column) => {
                let columnHasData = false;
                trial.data.forEach(datum => {
                    if (datum[column.handle]) {
                        columnHasData = true;
                    }
                });
                if (columnHasData) {
                    withData.push(column);
                }
            });
        }

        withData.forEach(c => {
            if (prefs?.hiddenResultColumns?.includes(c.handle)) {
                c.show = false;
            } else {
                c.show = true;
            }
        })
        console.log('withData', withData);
        setColumnsWithData(withData);

        let tempResultColumns = clone(withData).splice(1, withData.length);

        tempResultColumns.forEach(c => {
            if (prefs?.hiddenResultColumns?.includes(c.handle)) {
                c.show = false;
            } else {
                c.show = true;
            }
        })
        setResultColumns(tempResultColumns);

        console.log('clone(withData).splice(1, withData.length)', clone(withData).splice(1, withData.length));
        
        console.log('columnsWithData', columnsWithData);




    }, [trial]);

    useEffect(() => {

        console.log('resultColumns changed')
        console.log('resultColumns', resultColumns);

    }, [resultColumns]);


    function compareValues(key, order = 'desc', forceString) {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            let varA = a[key];
            let varB = b[key];

            if (varA === '-') {
                varA = 0;
            }

            if (varB === '-') {
                varB = 0;
            }

            if (!forceString) {
                if (isNumeric(varA)) {
                    varA = parseFloat(varA);
                }
                if (isNumeric(varB)) {
                    varB = parseFloat(varB);
                }
            }

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-us')
    };

    const sortTable = (column, forceString) => {
        if (!column.order) {

        }
        console.log('trial.columns', trial.columns);
        trial.columns.map(myColumn => {
            if (myColumn.handle !== column.handle) {
                myColumn.order = null;
            }
        })
        if (column.order) {
            if (column.order === 'asc') {
                column.order = 'desc';
            } else {
                column.order = 'asc';
            }
        } else {
            column.order = 'desc';
        }
        const sorted = trial.data.sort(compareValues(column.handle, column.order, forceString));
        
        setTrial({...trial, data: sorted})
    }




    const numberFormat = (value) => {
        if (!value) {
            return value;
        }
        if (value.startsWith('00')) {
            return value;
        }
        if (value === '-') {
            return value;
        }
        if (isNumeric(value)) {
            return Number.parseFloat(value).toFixed(decimals);
        }
        return value;
    };

    const isNumeric = (str) => {
        if (str === '-') {
            return true;
        }
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    };

    const ColumnHeader = ({column, index}) => {
        let classes = classNames( 'sortable text-center bg-white', {
            'sorting': column.order
        });
        let start = column.heading.indexOf("(");
        let end = column.heading.indexOf(")");
        let unit = column.heading.substring(start, end + 1);
        let unitlessHeading = column.heading;
        if (start > 0) {
            unitlessHeading = column.heading.substring(0,start);
        }
        return (
            <th
                data-key={column.handle}
                key={column.handle}
                onClick={() => sortTable(column, index === 0 || column.type === 'singleline')}
                className={classes}
                style={index === 0 ? {transform: 'translateX(' + columnTranslate + 'px)'} : {}}
            >
                <span className='column-heading'>{unitlessHeading}
                <span className="column-sort-indicator">
                    {column.order && (
                        <React.Fragment>
                            {column.order === 'desc' && (
                                <CaretDownFill />
                            )}
                            {column.order === 'asc' && (
                                <CaretUpFill />
                            )}

                        </React.Fragment>
                    )}
                </span>
                    </span>
                {unit && (
                    <span className="column-sort-indicator__unit text-secondary" ><br />{unit}</span>
                )}
            </th>
        )
    };

    const CatStat = ({label, cat, format}) => (
        <>
            {cat && (
                <>
                    {format === 'tableRow' ? (
                        <tr>
                            <th>{label}</th>
                            <td>{cat.title || cat}</td>
                        </tr>
                    ) : (
                        <div className="col-md-3 mb-4">
                            <div className="card  card-stat">
                                <div className="card-body">
                                    <h3 className="card-title">{label}</h3>
                                    <div className="stat-value">{cat.title || cat}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );

    const SiteDescriptionStat = ({label, trial, format}) => (
        <>
            {trial && (
                <>
                    {format === 'tableRow' ? (
                        <tr>
                            <th>{label}</th>
                            <td><SiteDescription trial={trial} /></td>
                        </tr>
                    ) : (
                        <div className="col-md-3 mb-4">
                            <div className="card  card-stat">
                                <div className="card-body">
                                    <h3 className="card-title">{label}</h3>
                                    <div className="stat-value"><SiteDescription trial={trial} /></div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );

    const DateStat = ({label, date, format}) => (
        <>
            {date && (
                <>
                    {format === 'tableRow' ? (
                        <tr>
                            <th>{label}</th>
                            <td>{formatDate(date)}</td>
                        </tr>
                    ) : (
                        <div className="col-md-3 mb-4">
                            <div className="card  card-stat">
                                <div className="card-body">
                                    <h3 className="card-title">{label}</h3>
                                    <div className="stat-value">{formatDate(date)}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );

    const customModalStyles = {
        overlay: {
            zIndex: 100
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 20,
        },
    };

    const handleCheck = (event) => {
        console.log('event', event);
        console.log('event.target', event.target);
        console.log('event.target.value', event.target.value);
        const clonedColumns = clone(columnsWithData);
        clonedColumns.forEach(c => {
            if (c.handle === event.target.value) {
                c.show = !c.show;
            }
        })
        setColumnsWithData(clonedColumns);


        const clonedResultColumns = clone(resultColumns);
        clonedResultColumns.forEach(c => {
            if (c.handle === event.target.value) {
                c.show = !c.show;
            }
        })
        setResultColumns(clonedResultColumns);

        // Update React context prefs
        const clonedPrefs = clone(prefs);
        updatePrefs({
            ...clonedPrefs,
            hiddenResultColumns: clonedResultColumns.filter(c => c.show === false)
                .map(c => c.handle)
        });
    }

    return (
        <div className="mt-4">

            <Helmet>
                <title>NDSU Variety Trials {trial.title || 'Trial Data'}</title>
            </Helmet>

            {isLoading ? (
                <Loader />
            ) : (

                <div>
                    {trial.title && (
                        <div>

                            {!isComparing && (
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={`/list`}>Trials</Link></li>
                                        <li className="breadcrumb-item"><Link to={`/list/${trial.crop.slug}`}>{trial.crop.title}</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{trial.title}</li>
                                    </ol>
                                </nav>
                            )}

                            <h1 className={`trial-title trial-title-${isComparing ? 'comparing text-truncate' : 'single'}`} >{trial.title}</h1>



                            {isComparing && (
                                <button
                                    className="btn  btn-outline-primary btn-sm mb-3"
                                    onClick={() => {
                                        setShowTrialInfo(!showTrialInfo);
                                    }}
                                >
                                    {showTrialInfo ? (
                                        <>Hide Info <CaretUpFill /></>
                                    ) : (
                                        <>Show Info <CaretDownFill /></>
                                    )}
                                </button>
                            )}

                            {showTrialInfo && (
                                <>
                                    <div className={`row mb-3 mt-3 ${!isComparing ? 'd-md-none' : ''}`}>
                                        <div className={`${!isComparing ? 'col-md-6' : 'col'}`}>
                                            <table className="table table-sm table-bordered">
                                                <tbody>
                                                <CatStat format={'tableRow'} label="Location" cat={trial.location} />
                                                <CatStat format={'tableRow'} label="Crop" cat={trial.crop} />
                                                <CatStat format={'tableRow'} label="Previous Crop" cat={trial.previousCrop} />
                                                <DateStat format={'tableRow'} label="Planting Date" date={trial.plantingDate?.date} />
                                                <DateStat format={'tableRow'} label="Harvest Date" date={trial.harvestDate?.date} />
                                                <SiteDescriptionStat format={'tableRow'} label="Site Description" trial={trial} />
                                                <CatStat format={'tableRow'} label="Treatment" cat={trial.treatment} />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className={`row mb-4 mt-3 d-none ${!isComparing ? 'd-md-flex' : ''}`}>
                                        <CatStat label="Location" cat={trial.location} />
                                        <CatStat label="REC" cat={trial.rec} />
                                        <CatStat label="Crop" cat={trial.crop} />
                                        <CatStat label="Previous Crop" cat={trial.previousCrop} />
                                        <DateStat label="Planting Date" date={trial.plantingDate?.date} />
                                        <DateStat label="Harvest Date" date={trial.harvestDate?.date} />
                                        <SiteDescriptionStat label="Site Description" trial={trial} />

                                        <CatStat label="Treatment" cat={trial.treatment} />
                                        <CatStat label="Researchers" cat={trial.researchers} />
                                    </div>
                                    <div className="row my-4">
                                        {trial.information && (
                                            <div className="col">
                                                {parse(trial.information)}
                                            </div>
                                        )}

                                        {(trial.attachments && trial.attachments.length > 0) && (
                                            <div className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        Printable trial results and resources
                                                    </div>
                                                    <div className="card-body">
                                                        <ul className="list-unstyled">
                                                            {trial.attachments.map(attachment => (
                                                                <li key={attachment.id}>
                                                                    <a href={attachment.url} className="link-download" target="_blank" rel="noopener">
                                                                        <CloudDownload />&nbsp;&nbsp;{attachment.title} ({attachment.kind})
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                        </div>
                    )}

                    {trial.data && (
                        <div>
                            <ul className="nav nav-tabs mb-3">
                                <li className="nav-item">
                                    <a className={`nav-link ${viewMode === 'table' ? 'active' : 'inactive'}`} href="#"
                                       onClick={() => {
                                        setViewMode('table');
                                    }}>Table</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${viewMode === 'chart' ? 'active' : ''}`} href="#"
                                       onClick={() => {
                                           setViewMode('chart');
                                       }}>Chart</a>
                                </li>
                            </ul>

                            {viewMode === 'chart' && (
                                <BarChart trial={trial} attributes={columnsWithData} isComparing={isComparing} />
                            )}

                            {viewMode === 'table' && (
                                <div>
                                    <div className="row mb-3 d-flex flex-row">
                                        <div className="d-flex col-lg-3 col-md-4 col-sm-5 col-xs-6">
                                            <label htmlFor="inputEmail3" className="col-form-label">Decimals</label>
                                            <input
                                                className="form-control mx-2"
                                                type="number"
                                                value={decimals}
                                                onChange={(e) => {setDecimals(e.target.value)}}
                                            />

                                            <div>
                                                <button className="btn btn-primary" onClick={() => setSettingsModalOpen(!settingsModalOpen)}>
                                                    <GearFill />
                                                </button>
                                                {settingsModalOpen && (
                                                    <div className="position-relative">
                                                        <div className="card position-absolute mt-2 shadow" style={{zIndex: 20, width: 250}}>
                                                            <div className="card-body">
                                                                <ul className="list-unstyled">
                                                                    {columnsWithData.map((column, index) => (
                                                                        <li className="form-check" key={`chartOption${index}`}>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                value={column.handle}
                                                                                checked={column.show === true}
                                                                                onChange={handleCheck}
                                                                                // disabled={allChartable}
                                                                                id={`chartOption_${column.handle}`}/>
                                                                            <label className="form-check-label" htmlFor={`chartOption_${column.handle}`}>
                                                                                {column.heading}
                                                                            </label>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <Modal
                                            isOpen={false}
                                            // onAfterOpen={afterOpenModal}
                                            // onRequestClose={closeModal}
                                            style={customModalStyles}
                                            contentLabel="Example Modal"
                                        >
                                            <button onClick={() => setSettingsModalOpen(false)}>close</button>
                                            <ul className="list-unstyled">
                                                {columnsWithData.map((column, index) => (
                                                    <li className="form-check" key={`chartOption${index}`}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={column.heading}
                                                            checked={column.show === true}
                                                            // onChange={handleCheck}
                                                            // disabled={allChartable}
                                                            id={`chartOption_${column.handle}`}/>
                                                        <label className="form-check-label" htmlFor={`chartOption_${column.handle}`}>
                                                            {column.heading}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                            <button className="btn btn-primary">Apply</button>
                                            <button className="btn btn-secondary">Cancel</button>
                                        </Modal>
                                    </div>

                                    <div
                                        className="table-responsive"
                                        ref={tableWrapperRef}
                                        onScroll={(e) => {
                                            const wrapperRect = tableWrapperRef.current?.getBoundingClientRect();
                                            const rect = tableRef.current?.getBoundingClientRect();
                                            const diff = wrapperRect.left - rect.left;
                                            setColumnTranslate(diff);
                                        }}
                                    >
                                        <table ref={tableRef} className="table table-sm table-trial ">
                                            <thead
                                                ref={tableHeaderRef}
                                                className="bg-white thead-sticky  position-relative"
                                                style={{zIndex: 10, transform: 'translateY(' + thTranslate + 'px)'}}
                                            >
                                            <tr>
                                                {columnsWithData.filter(c => c.show).map((column, index) => (
                                                    <ColumnHeader column={column} key={column.handle} index={index} />
                                                ))}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {trial.data.map((datum, index) => (
                                                <tr key={index}>
                                                    {columnsWithData.filter(c => c.show).map((column, index) => {
                                                        let value = datum[column.handle];
                                                        return (
                                                            <td
                                                                style={index === 0 ? {transform: 'translateX(' + columnTranslate + 'px)'} : {}}
                                                                key={index + column.handle}
                                                                className={`text-nowrap bg-white text-center`}
                                                            >
                                                                {column.type === 'number' ? numberFormat(value) : value}
                                                            </td>
                                                        )
                                                    })}

                                                    {/*{Object.entries(datum).map(([key, value], colIndex) => (*/}
                                                    {/*    <td key={index + key} className={(isNumeric(value) && colIndex > 0) ? 'text-end' : ''}>*/}
                                                    {/*        {numberFormat(value)}*/}
                                                    {/*    </td>*/}
                                                    {/*))}*/}
                                                </tr>
                                            ))}
                                            </tbody>
                                            {/*<thead>*/}
                                            {/*<tr>*/}
                                            {/*    <th className="text-center text-secondary" colSpan={trial.columns.length}>*/}
                                            {/*        Results*/}
                                            {/*    </th>*/}
                                            {/*</tr>*/}
                                            {/*</thead>*/}
                                            <tbody className="">
                                            {trial.trialDataComputedMean && (
                                                <tr>
                                                    <th>Mean</th>
                                                    {resultColumns.filter(c => c.show).map(column => (
                                                        <React.Fragment key={`result-mean-${column.handle}`}>
                                                            <td className="text-center" >
                                                                {numberFormat(trial.trialDataComputedMean[column.handle]) || ''}
                                                            </td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            )}
                                            {trial.trialDataComputedCv && (
                                                <tr>
                                                    <th>CV (%)</th>
                                                    {resultColumns.filter(c => c.show).map(column => (
                                                        <React.Fragment key={`result-cv-${column.handle}`}>
                                                            <td className="text-center">
                                                                {numberFormat(trial.trialDataComputedCv[column.handle]) || ''}
                                                            </td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            )}

                                            {trial.trialDataComputedLsd010 && (
                                                <tr>
                                                    <th>LSD (0.10)</th>
                                                    {resultColumns.filter(c => c.show).map(column => (
                                                        <React.Fragment key={`result-lsd10-${column.handle}`}>
                                                            <td className="text-center">
                                                                {numberFormat(trial.trialDataComputedLsd010[column.handle]) || ''}
                                                            </td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            )}
                                            {trial.trialDataComputedLsd005 && (
                                                <tr>
                                                    <th>LSD (0.05)</th>
                                                    {resultColumns.filter(c => c.show).map(column => (
                                                        <React.Fragment key={`result-lsd5-${column.handle}`}>
                                                            <td className="text-center">
                                                                {numberFormat(trial.trialDataComputedLsd005[column.handle]) || ''}
                                                            </td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}
                </div>
            )}

            {trial.footnotes && (
                <div className="mt-4">
                    <p className="small">
                        {parse(trial.footnotes)}
                    </p>
                </div>
            )}

            {/*<pre>{JSON.stringify(trial, null, 4)}</pre>*/}
        </div>

    );
};

TrialDetail.propTypes = {};

export default TrialDetail;
