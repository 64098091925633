import React, {useEffect, useState} from 'react';
import {API_URL} from "../utils/paths";



const Footer = () => {
    const [showNav, setShowNav] = useState(false);
    const [navItems, setNavItems] = useState([]);


    useEffect(() => {

        fetchNavItems();

    }, []);


    const fetchNavItems = () => {
        const url = new URL(API_URL + 'api/nav/?handle=footer');

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setNavItems(result.data);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }
    return (
        <div className="footer-wrapper">

            <section role="prefooter" className="prefooter-wrapper">
                <div className=" prefooter-inner">
                    <div className="prefooter container">


                        <div className="row">
                            <div className="col-md-8">
                                <div id="block-logoaddress"
                                     className="block block-block-content7142daf1-fa15-4e7d-bb57-6ce176ffd93f block-image-text block-logo--address block-5">


                                    <div className="field field-image entity-reference">

                                        <article className="media-item image thumbnail-non-cropped"
                                                 data-quickedit-entity-id="media/19">

                                            <div className="media-image-wrapper">


                                                <div className="field field-media-image image">
                                                    <img
                                                        src="https://www.ndsu.edu/agriculture/sites/default/files/styles/thumbnail/public/2021-02/NDSU-logo_0.png?itok=nLT_wz2l"
                                                        alt="NDSU logo" loading="lazy" typeof="foaf:Image"
                                                        className="image-style-thumbnail" width="100" height="28" />


                                                </div>


                                            </div>

                                        </article>
                                    </div>


                                    <div className="field body text-with-summary">
                                        <p>1230 Albrecht Blvd, Fargo ND&nbsp; 58102<br />
                                            Mailing address:&nbsp; NDSU Dept. 7520, PO Box 6050, Fargo, ND&nbsp; 58108-6050</p>
                                    </div>

                                </div>

                                <div data-quickedit-entity-id="block_content/2" id="block-footerabout"
                                     className="block block-block-content16ff8902-af86-4632-aa2b-baf91194b49b block-basic block-footer-about block-2">


                                    <div className="field body text-with-summary">
                                        <p>North Dakota State University is distinctive as a student-focused, land-grant,
                                            research university.&nbsp;NDSU Agricultural Affairs educates students with interests
                                            in agriculture, food systems and natural resources; fosters communities through
                                            partnerships that educate the public; provides creative, cost-effective solutions to
                                            current problems; and pursues fundamental and applied research to help shape a
                                            better world.</p>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-4">
                                <nav aria-labelledby="block-footer-menu" id="block-footer-menu"
                                     className="block--system-menu quicklinks block-footer-menu">
                                    <h2 className="block-title visually-hidden">Quicklinks</h2>


                                    <ul className="list-unstyled text-right">
                                        {navItems.map((item, i) => (
                                            <li className="nav-item" key={item.id}>
                                                <a href={item.url} className="nav-link">{item.title}</a>
                                            </li>
                                        ))}
                                    </ul>


                                </nav>
                            </div>
                        </div>




                    </div>

                </div>
            </section>

            <footer role="contentinfo" className="site-footer">
                <div className="footer-inner">
                    <div className="container footer">

                        <div data-quickedit-entity-id="block_content/3" id="block-footercopyright"
                             className="block block-block-contenta809c6bf-f07f-4590-8ca3-722c530d29ac block-basic block-footer-copyright block-3">


                            <div className="field body copyright">
                                <span className="copyright-year">©2021</span><p>North Dakota State University, an equal
                                opportunity, affirmative action institution.</p></div>
                        </div>

                        <div data-quickedit-entity-id="block_content/4" id="block-footercontact"
                             className="block block-block-content0391c0e4-d7e3-447a-8420-50adc9323e17 block-basic block-footer-contact block-4">


                            <div className="field body text-with-summary">
                                <p>Contact NDSU Agricultural Affairs at&nbsp;<a href="tel:+17012317655">(701)
                                    231-7655</a> | <a href="https://www.ndsu.edu/privacy/" target="_blank"
                                                      rel="noreferrer">Privacy Statement</a> | <a
                                    href="https://www.ndsu.edu/equity/required_non_discrimination_statement/"
                                    target="_blank" rel="noreferrer">Non-discrimination Statement</a></p>
                            </div>

                        </div>

                    </div>

                </div>
            </footer>

        </div>
    );
};

Footer.propTypes = {};

export default Footer;
