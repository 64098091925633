import React, {useEffect, useState} from 'react';

import {NavLink} from "react-router-dom";
import classNames from "classnames";

import logo from './../images/logo.svg';
import {API_URL} from "../utils/paths";


const Footer = () => {
    const [showNav, setShowNav] = useState(false);
    const [navItems, setNavItems] = useState([]);


    useEffect(() => {

        fetchNavItems();

    }, []);


    const fetchNavItems = () => {
        const url = new URL(API_URL + 'api/nav/');

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setNavItems(result.data);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }

    let classes = classNames( 'collapse', 'navbar-collapse', {
        'show': showNav,
    });

    return (
        <nav className="navbar-main navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
                <NavLink className="navbar-brand" to="/" style={{width: 250}}>
                    <img src={logo} alt=""/>
                </NavLink>
                <button
                    className="navbar-toggler"
                    type="button"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => setShowNav(!showNav)}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={classes} id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        {navItems.map(item => (
                            <li className="nav-item">
                                <a href={item.url} className="nav-link">{item.title}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

Footer.propTypes = {};

export default Footer;
