import React, {useState, useEffect} from 'react';

import queryString from 'query-string';

import TrialDetail from "./TrialDetail";
import {Link} from "react-router-dom";


const Compare = () => {

    const [compareIds, setCompareIds] = useState([]);

    useEffect(() => {

        console.log('loaded')

        const parsed = queryString.parse(window.location.search);
        if (parsed.ids) {
            setCompareIds(parsed.ids.split(','))
        }
    }, []);

    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={`/list`}>Trials</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Compare</li>
                </ol>
            </nav>
            <h1>Compare</h1>

            <div style={{width: '100%', overflowX: 'auto'}}>
                <div style={{minWidth: 375 * compareIds.length}}>
                    <div className="row">

                        {compareIds.map(id => (
                            <div className={`col-${12/compareIds.length}`}>
                                <TrialDetail id={id} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    );
};

Compare.propTypes = {};

export default Compare;
