import React, {useState, useEffect, useContext} from 'react';
import {useRouteMatch, useParams} from "react-router";
import clone from 'clone';
import Select from 'react-select'
import AnimateHeight from 'react-animate-height';
import hash from 'object-hash';
import parse from 'html-react-parser';

import {Link, NavLink } from "react-router-dom";
import Modal from 'react-modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Helmet} from "react-helmet";
import {
    ArrowUpRightCircle,
    X,
    Plus,
    PlusCircleFill,
    ChevronBarUp,
    ChevronUp,
    ChevronDown,
    ExclamationTriangleFill,
    GearFill
} from 'react-bootstrap-icons';

import Map from "./Map";
import Loader from "./Loader";
import SiteDescription from "./SiteDescription";
import {API_URL} from "../utils/paths";
import {UserContext, AppContext} from "../App";


const TrialList = () => {
    const  params = useParams();

    const {prefs, updatePrefs} = useContext(UserContext);
    const {appSettings, setAppSettings} = useContext(UserContext);

    const [globals, setGlobals] = useState({});
    const [sidebarNav, setSidebarNav] = useState([]);
    const [trials, setTrials] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [years, setYears] = useState([]);
    const [locations, setLocations] = useState([]);
    const [recs, setRecs] = useState([]);
    const [rec, setRec] = useState(null);
    const [counties, setCounties] = useState([]);
    const [year, setYear] = useState('');
    const [location, setLocation] = useState('');
    const [locationList, setLocationList] = useState([]);
    const [county, setCounty] = useState('');
    const [crops, setCrops] = useState([]);
    const [currentCrop, setCurrentCrop] = useState({});
    const [cropFilters, setCropFilters] = useState({filters: []});
    const [previousCrops, setPreviousCrops] = useState([]);
    const [isLoading, setIsloading] = useState(true);
    const [previousCrop, setPreviousCrop] = useState('');
    const [toCompare, setToCompare] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [mapError, setMapError] = useState("");
    const [polygon, setPolygon] = useState([]);
    const [advancedFiltersHeight, setAdvancedFiltersHeight] = useState(0);
    const match = useRouteMatch();

    const [showZip, setShowZip] = useState(false);
    const [zip, setZip] = useState('');
    const [radius, setRadius] = useState(50);
    const [zipError, setZipError] = useState("");

    const [columns, setColumns] = useState([]);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);

    const {crop} = match.params;

    const initialColumns = [
        {
            heading: 'Planting Date',
            show: true,
            renderCell: (trial) => (
                <>
                    {trial.plantingDate ? (
                        <span>{formatDate(trial.plantingDate.date)}</span>
                    ) : (
                        <span>-</span>
                    )}
                </>
            )
        },
        {
            heading: 'Harvest Date',
            show: true,
            renderCell: (trial) => (
                <>
                    {trial.harvestDate ? (
                        <span>{formatDate(trial.harvestDate.date)}</span>
                    ) : (
                        <span>-</span>
                    )}
                </>
            )
        },
        {
            heading: 'Crop',
            show: true,
            renderCell: (trial) => (
                <>
                    {trial.crop ? (
                        <span>{trial.crop.title}</span>
                    ) : (
                        <span>unknown</span>
                    )}
                </>
            )
        },
        {
            heading: 'Previous Crop',
            show: true,
            renderCell: (trial) => (
                <>
                    {trial.previousCrop ? (
                        <span>{trial.previousCrop.title}</span>
                    ) : (
                        <span>unknown</span>
                    )}
                </>
            )
        },
        {
            heading: 'Year',
            show: true,
            renderCell: (trial) => (
                <>
                    {trial.year ? (
                        <span>{trial.year.title}</span>
                    ) : (
                        <span>unknown</span>
                    )}
                </>
            )
        },
        {
            heading: 'REC',
            show: true,
            renderCell: (trial) => (
                <>
                    {trial.rec ? (
                        <span>{trial.rec.title}</span>
                    ) : (
                        <span>unknown</span>
                    )}
                </>
            )
        },
        {
            heading: 'Location',
            show: true,
            renderCell: (trial) => (
                <>
                    {trial.location ? (
                        <span>{trial.location.title}</span>
                    ) : (
                        <span>unknown</span>
                    )}
                </>
            )
        },
        {
            heading: 'Site Description',
            show: true,
            renderCell: (trial) => (
                <>
                    <SiteDescription trial={trial} />
                </>
            )
        },
    ];

    useEffect(() => {
        console.log('crop changed')
        let found = crops.find(c => c.slug === crop)


        console.log('found', found);
        if (found) {
            setCurrentCrop(found);
            found.filterOptions = found?.availableFilters?.map((f) => {
                console.log('f', f);
                return {value: f.handle, label: f.label};
            });
            found.filterOperators = [
                {value: '>', label: 'Greater Than'},
                // {value: '>=', label: '>='},
                {value: '<', label: 'Less Than'},
                // {value: '<=', label: '<='},
                {value: '===', label: 'Equal To'},
            ]

        }
    }, [crop, crops]);

    useEffect(() => {
        console.log('current crop changed')
        // handleAddFilter();

        console.log('currentCrop', currentCrop);

        if (currentCrop.filterOperators) {
            handleAddFilter();
        }
    }, [currentCrop]);

    useEffect(() => {
        setIsloading(true);

        console.log('params changed');
        fetchTrials();

        // if (prefs?.filters !== undefined) {
        //     const clonedPrefs = clone(prefs);
        //     const updated = {
        //         ...clonedPrefs,
        //         filters: {rec, year, county, location}
        //     };
        //     updatePrefs(updated);
        // }
    }, [rec, year, crop, county]);

    useEffect(() => {

        if (location === 'useMap') {
            console.log('using map');
            setShowMap(true);
            setShowZip(false);
        } else if (location === 'useZip') {
            setShowZip(true);
            setShowMap(false);
        } else {
            setShowZip(false);
            setShowMap(false);
            setIsloading(true);
            setTrials([]);
            setPageNumber(1);
            console.log('⭐⭐ fetching trials cuz location changed ⭐⭐')
            fetchTrials();
        }

        // console.log('location changed')
        // console.log('prefs', prefs);
        // if (prefs?.filters !== undefined) {
        //     console.log('⭐proceed⭐')
        //     const clonedPrefs = clone(prefs);
        //     const updated = {
        //         ...clonedPrefs,
        //         filters: {rec, year, county, location}
        //     };
        //     updatePrefs(updated);
        // }

    }, [location]);

    useEffect(() => {

        if (params.year) {
            setYear(params.year);
        }

        fetchGlobals();
        fetchCrops();
        fetchYears();
        fetchLocations();
        fetchRecs();
        fetchCounties();
        fetchNav();

        buildColumns(initialColumns);
    }, []);

    useEffect(() => {
        buildColumns(initialColumns);

        // if (prefs?.filters?.year) {
        //     console.log('have year');
        //     setYear(prefs.filters.year);
        // }
        //
        // if (prefs?.filters?.county) {
        //     console.log('have county');
        //     setCounty(prefs.filters.county);
        // }
        //
        // if (prefs?.filters?.rec) {
        //     console.log('have rec');
        //     setRec(prefs.filters.rec);
        // }
        //
        // if (prefs?.filters?.location) {
        //     console.log('have location');
        //     setLocation(prefs.filters.location);
        //     setLocationList(prefs.filters.location.split(','));
        // }

    }, [prefs]);

    useEffect(() => {

        console.log('trials changed')
        console.log('trials.length', trials.length);
        console.log('trials', trials);
    }, [trials]);

    const buildColumns = (columnsToBuild) => {
        console.log('⭐⭐ building columns')
        console.log('prefs', prefs);
        console.log('columnsToBuild', columnsToBuild);
        if (prefs?.hiddenListColumns) {
            const clonedCols = clone(columnsToBuild);
            clonedCols.map(c => {
                console.log('c', c);
                if (prefs.hiddenListColumns.includes(c.heading)) {
                    c.show = false;
                }
                return c;
            })
            console.log('clonedCols', clonedCols);
            setColumns(clonedCols);
        }
    }

    const buildQuaryParams = (filters, page) => {
        let params = {};
        const queryParams = new URLSearchParams();

        queryParams.append('per_page', 40);
        if (crop) {
            params.crop = crop;
            queryParams.append('crop', crop);
        }
        if (year) {
            params.year = year;
            queryParams.append('year', year);
        }
        if (rec) {
            params.rec = rec;
            queryParams.append('rec', rec);
        }
        if (location) {
            console.log('ave location')
            if (location === 'useZip') {
                console.log('using zip')
                params.zip = zip;
                params.radius = radius;
                queryParams.append('zip', zip);
                queryParams.append('radius', radius);

            } else {
                params.location = location;
                queryParams.append('location', location);
            }
        }

        console.log('county', county);
        if (county) {
            params.county = county;
            queryParams.append('county', county);
        }

        if (page) {
            queryParams.append('page', page);
            setPageNumber(page);
        } else {
            queryParams.append('page', pageNumber);
        }

        if (filters) {
            const filterHash = hash(filters);
            queryParams.append('filter_hash', filterHash);
        }

        console.log('queryParams', queryParams);
        return queryParams.toString();
    }

    const fetchTrials = (filters) => {
        console.log('filters', filters);

        // console.log('filterHash', filterHash);
        const url = new URL(API_URL + 'api/trials/');
        setIsloading(true);

        url.search = buildQuaryParams(filters, 1);
        // history.push({search: queryParams.toString()});
        fetch(url, {
            method: 'POST',
            headers: {"Content-type": "application/json; charset=UTF-8"},
            body: filters ? JSON.stringify(filters) : null,
        })
            .then(res => res.json())
            .then(
                (result) => {
                    result.data.map(d => {
                        d.comparing = false;
                        return d;
                    })


                    const filtered = result.data.filter(t => !t.hide);
                    console.log('page number is 1!!!!!!!');
                    console.log('setting trials');
                    setTrials(filtered);

                    setIsloading(false);

                    if (result.meta.pagination.total_pages > result.meta.pagination.current_page) {
                        console.log('have more pages')
                        setPageNumber(result.meta.pagination.current_page + 1);
                        setHasMore(true);
                    } else {
                        setHasMore(false);
                    }
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }

    const fetchMoreTrials = (filters) => {
        const url = new URL(API_URL + 'api/trials/');
        // setIsloading(true);


        url.search = buildQuaryParams(filters);
        // history.push({search: queryParams.toString()});

        fetch(url, {
            method: 'POST',
            headers: {"Content-type": "application/json; charset=UTF-8"},
            body: filters ? JSON.stringify(filters) : null,
        })
            .then(res => res.json())
            .then(
                (result) => {
                    result.data.map(d => {
                        d.comparing = false;
                        return d;
                    })

                    const filtered = result.data.filter(t => !t.hide);
                    const clonedTrials = clone(trials);

                    setTrials([...clonedTrials, ...filtered]);

                    setIsloading(false);

                    if (result.meta.pagination.total_pages > result.meta.pagination.current_page) {
                        setPageNumber(result.meta.pagination.current_page + 1);
                        setHasMore(true);
                    } else {
                        setHasMore(false);
                    }
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }

    const fetchGlobals = () => {
        const url = new URL(API_URL + 'api/globals/');

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setGlobals(result);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )

    }

    const fetchNav = () => {
        const url = new URL(API_URL + 'api/nav/?handle=sidebar');

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setSidebarNav(result.data);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )

    }


    const fetchCrops = () => {
        const url = new URL(API_URL + 'api/crops/');

        fetch(url + '?all=true')
            .then(res => res.json())
            .then(
                (result) => {
                    setPreviousCrops(result.data);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setCrops(result.data);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }

    const fetchYears = () => {
        const url = new URL(API_URL + 'api/years/');

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setYears(result.data);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    };

    const fetchLocations = () => {
        const url = new URL(API_URL + 'api/locations/');

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setLocations(result.data);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }

    const fetchRecs = () => {
        const url = new URL(API_URL + 'api/recs/');

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setRecs(result.data);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }

    const fetchLocationsByZip = () => {
        setZipError('');
        const url = new URL(API_URL + 'api/locations-by-zip/');

        const queryParams = new URLSearchParams();
        queryParams.append('zip', zip);
        queryParams.append('radius', radius);
        console.log('queryParams', queryParams);
        url.search = queryParams.toString();
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    console.log('result', result);
                    // setLocations(result.data);
                    let slugs = [];
                    result.data.forEach(d => slugs.push(d.slug));
                    console.log('slugs', slugs);
                    if (slugs.length > 0) {
                        console.log('we have slugs')
                        setLocationList(slugs);
                        setLocation(slugs.toString());
                    } else {
                        console.log('no slugs')
                        setZipError('There are no matching locations. Please enter a new location or adjust the radius')
                    }


                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }

    const fetchCounties = () => {
        const url = new URL(API_URL + 'api/counties/');

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setCounties(result.data);
                },
                (error) => {
                    console.log('Error');
                    console.error(error);
                }
            )
    }

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-us')
    };

    const handleCompareCheck = (event) => {
        const checkedId = parseInt(event.target.value);

        const {value} = event.target;
        let compareIds = []
        let clonedTrials = clone(trials);
        clonedTrials.map((trial) => {
            if (trial.id === checkedId) {
                trial.comparing = !trial.comparing;
            }
            return trial;
        });
        clonedTrials.forEach(trial => {
            if (trial.comparing) {
                compareIds.push(trial.id);
            }
        });
        setToCompare(compareIds);
        setTrials(clonedTrials);

    }

    const handleLocationChange = (e) => {
        setLocation(e.target.value);
    }

    const handleZipChange = (e) => {
        // setTrials([]);
        // setPageNumber(1);
        const matchingLocations = fetchLocationsByZip();
    }

    const customStyles = {
        // content: {
        //     width: '50%',
        //     height: '50%'
        // },
    };


    function closeModal() {
        setModalIsOpen(false);
    }

    const handlePolygonChange = (selected) => {
        console.log('polygon changed')
        console.log('selected', selected);
        
        let slugs = selected.map(i => i.slug);
        console.log('slugs', slugs);
        setLocationList(slugs);

        setTrials([]);
        setPageNumber(1);
        setLocation(slugs.toString());
        setShowMap(false);
    }


    const handleCheck = (event) => {
        console.log('event.target.value', event.target.value);
        const clonedColumns = clone(columns);
        clonedColumns.forEach(c => {
            if (c.heading === event.target.value) {
                c.show = !c.show;
            }
        })

        setColumns(clonedColumns);

        const clonedPrefs = clone(prefs);

        updatePrefs({
            ...clonedPrefs,
            hiddenListColumns: clonedColumns.filter(c => c.show === false)
                .map(c => c.heading)
        });


    }

    const handleAddFilter = () => {
        console.log('handleAddFilter')
        let clonedFilters = clone(cropFilters);
        console.log('currentCrop', currentCrop);
        clonedFilters.filters.push({
            handle: '',
            operator: null,
            value: 0
        });

        setCropFilters(clonedFilters);
    }

    const handleClearFilters = () => {
        setCropFilters()
    }

    const handleRemoveFilter = (index) => {
        console.log('remove filter');
        console.log('index', index);
        let clonedFilters = clone(cropFilters);
        clonedFilters.filters.splice(index, 1);
        setCropFilters(clonedFilters);
    }

    const handleApplyFilters = () => {
        const filteredValueFilters = cropFilters.filters.filter(f => {
            return !!f.handle.value && !!f.operator.value && !! f.value;
        })
        const valueFilters = filteredValueFilters.map(f => {

            return {
                handle: f.handle.value,
                operator: f.operator.value,
                value: f.value,
            }
        })
        console.log('valueFilters', valueFilters);
        fetchTrials({valueFilters});
    }

    const handleChangeFilter = (index, event, field) => {
        console.log('index', index);
        console.log('event', event);
        console.log('event.target', event.target);
        console.log('field', field);

        let val;
        if (field === 'value') {
            val = event.target.value;
        } else {
            val = event;
        }
        let clonedFilters = clone(cropFilters.filters);
        console.log('clonedFilters[index][field]', clonedFilters[index][field]);
        clonedFilters[index][field] = val;
        console.log('clonedFilters', clonedFilters);
        setCropFilters({filters: clonedFilters});
    }

    

    const Filters = () => (
        <div className="row">
            {/*<button className="btn btn-primary">Open</button>*/}
            {/*<Modal*/}
            {/*    isOpen={modalIsOpen}*/}
            {/*    // onAfterOpen={afterOpenModal}*/}
            {/*    onRequestClose={() => setModalIsOpen(false)}*/}
            {/*    style={customStyles}*/}
            {/*    ariaHideApp={false}*/}
            {/*    className="map-modal"*/}
            {/*    overlayClassName="map-modal__overlay"*/}
            {/*    contentLabel="Example Modal"*/}
            {/*>*/}
            {/*    <button className="map-modal__close position-absolute top-0 end-0 btn btn-primary index " onClick={closeModal}>close</button>*/}
            {/*    <p>Draw a polygon</p>*/}
            {/*    <Map locations={locations} poly={polygon} handlePolygonChange={handlePolygonChange} />*/}

            {/*</Modal>*/}

            <div className="col">
                <label htmlFor="exampleFormControlInput1" className="form-label small">Year</label>
                <select value={year} className="form-select form-select-sm" onChange={(e) => {
                    setTrials([]);
                    setPageNumber(1);
                    setYear(e.target.value);
                }}>
                    <option value="">All Years</option>

                    {years.map((year => (
                        <option key={year.slug} value={year.slug}>{year.title}</option>
                    )))}
                </select>
            </div>

            <div className="col">
                <label htmlFor="exampleFormControlInput1" className="form-label small">County</label>
                <select value={county} className="form-select form-select-sm" onChange={(e) => {
                    setTrials([]);
                    setPageNumber(1);
                    setCounty(e.target.value);
                }}>
                    <option value="">All Counties</option>

                    {counties.map(((county) => (
                        <option key={county.slug} value={county.slug}>{county.title}</option>
                    )))}
                </select>
            </div>
            
            <div className="col">
                <label htmlFor="exampleFormControlInput1" className="form-label small">REC</label>
                <select value={rec} className="form-select form-select-sm" onChange={(e) => {
                    setTrials([]);
                    setPageNumber(1);
                    setRec(e.target.value);
                }}>
                    <option value="">All RECs</option>

                    {recs.map((rec => (
                        <option key={rec.id} value={rec.id}>{rec.title}</option>
                    )))}
                </select>
            </div>
            
            <div className="col">
                <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label small"
                    onClick={() => setModalIsOpen(true)}
                >
                    Location
                </label>

                <div className="d-flex">
                    {locationList.length <= 1 && (

                        <>
                            <select
                                value={location}
                                className="form-select form-select-sm"
                                onChange={handleLocationChange}
                            >
                                <option value="">All Locations</option>
                                <optgroup label="Advanced">
                                    <option key={'useMap'} value={'useMap'}>Use Map</option>
                                    <option key={'useZip'} value={'useZip'}>Enter Zip Code</option>
                                </optgroup>

                                <optgroup label="List">
                                    {locations.map((location => (
                                        <option key={location.slug} value={location.slug}>{location.title}</option>
                                    )))}
                                </optgroup>
                            </select>
                            {/*<button className="btn btn-link btn-sm" onClick={() => setShowMap(!showMap)}>*/}
                            {/*    {!showMap ? 'Select With' : 'Hide'} Map*/}
                            {/*</button>*/}
                            {/*<button className="btn btn-link btn-sm" onClick={() => setShowMap(!showMap)}>*/}
                            {/*    {!showMap ? 'Select With' : 'Hide'} Map*/}
                            {/*</button>*/}
                        </>
                    )}
                    {location.includes(',') && (
                        <p>
                            <button
                                className="btn btn-outline-primary btn-sm btn-outline"
                                onClick={() => handlePolygonChange([])}
                            >
                                {locationList.length} selected <X />
                            </button>
                            {/*<small>*/}
                            {/*    {locationList.length} locations selected via map. <a className="" onClick={() => handlePolygonChange([])}>Clear</a>*/}
                            {/*</small>*/}
                        </p>

                    )}
                    <div className="ms-4">

                        <div>
                            <TableSettings />
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
    const loadMore = () => {
        console.log('⭐ load more');
        fetchMoreTrials();
    }

    const TrialsTable = ({trials}) => (


        <InfiniteScroll
            initialLoad={false}
            hasMore={hasMore}
            next={loadMore}

            // element={'tbody'}
            useWindow={true}
            loader={<div className="text-center"><Loader size="small" /></div>}
            dataLength={trials.length}
        >
            <table className="table table-sm" key={'fooooo'}>
                <thead>
                <tr>
                    <th>Compare</th>
                    <th>Title</th>
                    {columns.filter(c => c.show).map((column, key) => (
                        <th key={key}>{column.heading}</th>
                    ))}

                </tr>
                </thead>
                <tbody>
                {trials.map((trial, i) => (
                    <TrialsTableRow trial={trial} key={i} />
                ))}
                </tbody>



            </table>
        </InfiniteScroll>

    )

    const TrialsTableRow = ({trial}) => (
        <tr key={trial.id}>
            <td>
                <input
                    className="form-check-input"
                    type="checkbox"
                    value={trial.id}
                    checked={trial.comparing}
                    onChange={handleCompareCheck}
                    id={`chartOption_all`} />
            </td>
            <td><Link to={`/view/${trial.id}`}>{trial.title}</Link></td>
            {columns.filter(c => c.show).map((column, key) => (
                <td key={key}>
                    {column.renderCell(trial)}
                </td>
            ))}


        </tr>
    )

    const TableSettings = () => (
        <div className="position-relative">
            <button className="btn btn-primary btn-sm" onClick={() => setSettingsModalOpen(!settingsModalOpen)}>
                <GearFill />
            </button>
            {settingsModalOpen && (
                <div className="card position-absolute end-0 mt-2 shadow" style={{zIndex: 20, width: 250}}>
                    <div className="card-body">
                        <ul className="list-unstyled">
                            {columns.map((column, index) => (
                                <li className="form-check" key={`chartOption${index}`}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={column.heading}
                                        checked={column.show === true}
                                        onChange={handleCheck}
                                        id={`hideColumn_${index}`}/>
                                    <label className="form-check-label" htmlFor={`hideColumn_${index}`}>
                                        {column.heading}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )

    return (
        <div className="mt-5">
            <Helmet>
                <title>NDSU Variety Trials | Browse Trials</title>
            </Helmet>
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col">
                </div>
            </div>
            <div className="row">

                <div className="col-md-3 col-xl-2">
                    <div className="list-group list-group-flush">
                        <NavLink to={`/list/`} exact={true}
                                 className="list-group-item list-group-item-action">
                            All Crops
                        </NavLink>
                        {crops.map((crop => (
                            <NavLink to={`/list/${crop.slug}`} exact={true} key={`cropLink_${crop.id}`}
                                     className="list-group-item list-group-item-action">
                                {crop.title}
                            </NavLink>
                        )))}
                    </div>

                    <div className="mt-3">
                        <ul className="list-unstyled">
                            {sidebarNav.map((item, key) => (
                                <li className="mb-2" key={key}>
                                    <a
                                        className="btn btn-outline-primary btn-sm d-block"
                                        href={item.url}
                                        target={item.newWindow ? '_blank' : '_self'}
                                    >
                                        {item.title}  <ArrowUpRightCircle />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="col-md-9 col-xl-10">
                    <div>
                        <h4><strong>Variety Trials</strong></h4>

                        {globals.trialListIntro && (
                            <>
                                <div style={{maxWidth: '90ch'}}>
                                    {parse(globals.trialListIntro)}
                                </div>
                                <hr/>
                            </>
                        )}
                    </div>
                    <div className="mb-3">

                        <Filters />

                        <div className="my-3">
                            {currentCrop?.availableFilters && (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => setAdvancedFiltersHeight(advancedFiltersHeight === 0 ? 'auto' : 0)}
                                >
                                    Advanced Filters for {currentCrop.title} {advancedFiltersHeight !== 0 ? <ChevronUp /> : <ChevronDown />}
                                </button>
                            )}
                        </div>
                        {currentCrop?.availableFilters && (
                            <>
                                <AnimateHeight height={advancedFiltersHeight}>
                                    {/*<p>Filter by select values. </p>*/}
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>Column</th>
                                            <th>Operator</th>
                                            <th>Value</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {cropFilters.filters.map((filter, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <Select
                                                        onChange={(event) => handleChangeFilter(i, event, 'handle')}
                                                        options={currentCrop.filterOptions}
                                                        value={filter.handle}

                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        onChange={(event) => handleChangeFilter(i, event, 'operator')}
                                                        options={currentCrop.filterOperators}
                                                        value={filter.operator}
                                                    />

                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        value={filter.value}
                                                        onChange={(event) => handleChangeFilter(i, event, 'value') }
                                                    />
                                                </td>
                                                <td>
                                                    {i !== 0 && (
                                                        <div className="btn-group">

                                                            <button
                                                                className="btn btn-outline-primary"
                                                                onClick={() => handleRemoveFilter(i)}
                                                            >
                                                                &ndash;
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <div className="d-flex flex-row justify-content-end">
                                        <button className="btn btn-sm btn-outline-primary me-2" onClick={handleAddFilter}>Clear Filters</button>
                                        <button className="btn btn-sm btn-outline-primary me-2" onClick={handleAddFilter}>Add a Filter</button>
                                        <button className="btn btn-sm btn-primary me-2" onClick={handleApplyFilters}>Apply Filters</button>
                                    </div>
                                </AnimateHeight>
                            </>
                        )}
                    </div>

                    {showZip && (
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Search by Zip Code</h5>
                                <div className="row gy-2 gx-3 align-items-center">

                                    <div className="col-auto">
                                        <label className="visually-hidden" htmlFor="autoSizingInputGroup">Username</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="autoSizingInputGroup"
                                                placeholder="Zip Code"
                                                onChange={(e) => {
                                                    console.log('e', e);
                                                    setZip(e.target.value);
                                                }}
                                                value={zip}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                                        <select
                                            className="form-select form-select-sm"
                                            id="autoSizingSelect"
                                            value={radius}
                                            onChange={(e) => {
                                                setRadius(e.target.value);

                                            }}
                                        >
                                            <option value="10">10 miles</option>
                                            <option value="25">25 Miles</option>
                                            <option value="50">50 miles</option>
                                            <option value="100">100 miles</option>
                                        </select>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            className="btn btn-primary btn-sm me-2"
                                            onClick={handleZipChange}
                                        >
                                            Submit
                                        </button>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={() => setShowZip(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                {zipError && (
                                    <div className="alert alert-warning alert-dismissible my-2">

                                        <ExclamationTriangleFill style={{marginRight: 8}} />
                                        {zipError}
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="alert"
                                            aria-label="Close"
                                            onClick={() => setZipError('')}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {showMap && (
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Search with Map</h5>
                                <Map locations={locations} poly={polygon} handlePolygonChange={handlePolygonChange} />
                            </div>
                        </div>
                    )}

                    {toCompare.length > 0 && (
                        <div className="mb-2">
                            <Link to={`/compare/?ids=${toCompare.join()}`} className="btn btn-primary btn-sm btn-block">Compare {toCompare.length} Trials</Link>
                        </div>
                    )}

                    {isLoading ? (
                        <div className="loading">
                            <Loader />
                        </div>
                    ) : (
                        <div>
                            {(trials && trials.length > 0) ? (
                                <div className="table-responsive">
                                    <TrialsTable trials={trials} />
                                </div>
                            ) : (
                                <div className="alert alert-info">
                                    No trial data found for your selection
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

TrialList.propTypes = {};

export default TrialList;
