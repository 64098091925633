import React, {useEffect, useRef, useState} from 'react';
import AnimateHeight from 'react-animate-height';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {polygon} from "@turf/turf";

mapboxgl.accessToken = 'pk.eyJ1IjoicGtiLW5kc3V2dCIsImEiOiJjbDFqcGlta3gyNzdqM2RzOXB3a3ZucHYwIn0.NP7EtPwC-so6woRR2y3Btg';


const Map = ({locations, handlePolygonChange}) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-100.5);
    const [lat, setLat] = useState(47.45593251245758);
    const [zoom, setZoom] = useState(6);
    const [geoJson, setGeoJson] = useState(null);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [height, setHeight] = useState('auto');


    // useEffect(() => {
    //
    //     if (!map.current) {
    //         console.log('returning')
    //         return;
    //     }
    //     console.log('GO!')
    //
    //
    //     setGeoJson(geo);
    //
    //     map.current.addSource('points', {
    //         type: "geojson",
    //         data: geo
    //     })
    // }, [locations, map.current])


    useEffect(() => {
        if (map.current) return; // initialize map only once

        if (!locations || !locations.length) return;

        const geo = {
            type: 'FeatureCollection',
            features: [],
        }

        geo.features = locations.map(l => (
            {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [l.map.lng, l.map.lat]
                },
                properties: {
                    title: l.title
                }
            }
        ));

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/outdoors-v11',

            center: [lng, lat],
            zoom: zoom
        });


        function handlePolygonUpdate(e) {
            console.log('handlePolygonUpdate()')
            console.log('e', e);

            const bounds = e.features[0].geometry.coordinates[0].map(c => c);
            console.log('bounds', bounds);

            const poly = polygon(e.features[0].geometry.coordinates);
            console.log('poly', poly);

            let included = [];
            locations.forEach((loc) => {
                const isInBounds = booleanPointInPolygon([loc.map.lng, loc.map.lat], poly);
                if (isInBounds) {
                    included.push(loc)
                }
            })
            setSelectedLocations(included);
            handlePolygonChange(included);

//             const data = draw.getAll();
//             const answer = document.getElementById('calculated-area');
//             if (data.features.length > 0) {
//                 const area = turf.area(data);
// // Restrict the area to 2 decimal points.
//                 const rounded_area = Math.round(area * 100) / 100;
//                 answer.innerHTML = `<p><strong>${rounded_area}</strong></p><p>square meters</p>`;
//             } else {
//                 answer.innerHTML = '';
//                 if (e.type !== 'draw.delete')
//                     alert('Click the map to draw a polygon.');
//             }
        }
        map.current.on('draw.create', handlePolygonUpdate);
        map.current.on('draw.delete', handlePolygonUpdate);
        map.current.on('draw.update', handlePolygonUpdate);
        map.current.on('load', () => {
            console.log('map loaded');
            map.current.loadImage(
                '/pin.png',
                (error, image) => {
                    if (error) throw error;
                    map.current.addImage('custom-marker', image);
                    map.current.addSource('points', {
                        'type': 'geojson',
                        'data': geo,
                    });

                    map.current.addLayer({
                        'id': 'points',
                        'type': 'symbol',
                        'source': 'points',
                        'layout': {
                            'icon-image': 'custom-marker',
                            'text-field': ['get', 'title'],
                            'text-size': 10,
                            'text-font': [
                                'Open Sans Semibold',
                                'Arial Unicode MS Bold'
                            ],
                            'text-offset': [0, 0.75],
                            'text-anchor': 'top'
                        }
                    });
                }
            );

        })




        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true
            },
            defaultMode: 'draw_polygon'
        });
        map.current.addControl(draw);
    }, [locations]);

    return (
        <>
            <p className="small">
                Click on the map to add points, drawing a polygon around the selection area.
                Double click to complete the selection
            </p>
            {/*<button*/}
            {/*    className="btn btn-primary btn-sm mb-3"*/}
            {/*    aria-expanded={height !== 0}*/}
            {/*    aria-controls="example-panel"*/}
            {/*    onClick={() => setHeight(height === 0 ? 'auto' : 0)}*/}
            {/*>*/}
            {/*    {height === 0 ? 'Open Map' : 'Close Map'}*/}
            {/*</button>*/}
            <AnimateHeight
                id="example-panel"
                duration={500}
                height={height} // see props documentation below
            >
                <div className="mapwrap">
                    <div ref={mapContainer} className="map-container"/>
                </div>

            </AnimateHeight>

            {/*{selectedLocations.length > 0 && (*/}
            {/*    <div className="my-3">*/}
            {/*        <h4>Selected:</h4>*/}
            {/*        <ul>*/}
            {/*            {selectedLocations.map(location => (*/}
            {/*                <li>{location.title}</li>*/}
            {/*            ))}*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*)}*/}

        </>
    );
};

Map.propTypes = {};

export default Map;
