import React from 'react';
import Loader from "react-loader-spinner";


const LoaderComponent = ({size}) => {

    let wh = 100;
    if (size === 'small') {
        wh = 40
    }
    return (
        <div className={`loading d-flex justify-content-center ${size !== 'small' && 'mt-5'}`}>
            <Loader
                type="ThreeDots"
                color="#ffcc00"
                height={wh}
                width={wh}
            />
        </div>
    );
};

LoaderComponent.propTypes = {};

export default LoaderComponent;
